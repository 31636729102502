// 套餐和a+b
<template>
  <el-row v-if="goodlist.length>0">
    <el-col :span="24">
      <div class="zone">
        <div class="HeaderTit">
           <h3>{{titmsg}}</h3>
           <span class="subhead1"  v-if="productType=='TimeBuy'">
               限时抢好物
           </span>
           <span class="subhead2"  v-else>
               好物提前购
           </span>
           <div class="moreInfo"  @click="jumpProductType">更多</div>
        </div>
        <el-row :span="24" :gutter="20"  style="margin: 0;">
            <ActivityItem :productType="productType" v-for="(item,index) in  goodlist" :key="index" :info="item"></ActivityItem>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<style lang="less" scoped>
@import "~style/index.less";
.zone {
  overflow: hidden;
  .HeaderTit{
      // margin-top:30px;
      margin-top:10px;
      padding: 14px 20px;
    background: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    h3 {
        float: left;
        margin-left: 10px;
        margin-right: 12px;
        color: #333;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
    }
    .subhead1 {
         color: #F56C28;
          font-size:13px;
          margin-bottom: -6px;
    }
    .subhead2 {
         color: #CF03F9;
          font-size:13px;
          margin-bottom: -6px;
    }
    .moreInfo {
        position: absolute;
        right:20px;
         cursor: pointer;
            &:hover{
                color: @theme;
            }
         .fonts();
         color: #666;
    }
  }
  ul {
    width: 228px;
    float: left;
    li {
      height: 292px;
      margin-top: 15px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .shopInfo {
    float: left;
    width: calc(100% - 228px);
    .item {
      margin-right: 0px !important;
      margin-left: 15px;
    }
    .item:nth-child(4n + 0) {
      margin-right: 0px;
    }
  }
}
</style>

<script>
// ActivityItem
const ActivityItem=()=>import('components/index/ActivityItem.vue');
import { TimeBuyList,OpenBookingList} from "api/activity.js";
// import Qs from "qs";
export default {
  name: "ActivityModule",
  data() {
    return {
      titmsg: "",
      currentIndex: 0,
      goodlist:[]
    };
  },
  props: {
    productType: {
      type: String,
    },
  },
  created() {
      if (this.productType == "TimeBuy") {
          this.titmsg='限时抢购'
          // order为1：销量排序，2：价格排序，3：上架时间排序
          let datas={
            current:'1',
            order:1,
            size:6
          }
          TimeBuyList(datas).then(res=>{
             this.goodlist=res.data.data.records.slice(0,6)
              console.log('限时抢购',this.goodlist)
          })
      }else{
           this.titmsg='预售专区';
           let datas={
            // current:'1',
            // order:1,
            // size:3
          }
           OpenBookingList(datas).then(res=>{
             this.goodlist=res.data.data.slice(0,6)
               console.log('预售专区',this.goodlist)
          })
      }
  },
  methods: {
      jumpProductType(){
           if(this.productType=='OpenBooking'){
            //    alert("预售")
                 this.$router.push({name:"ActivityModuleList",query:{ActivityType:1}})
           }else if(this.productType=='TimeBuy'){
            //    alert("限时抢购")
                this.$router.push({name:"ActivityModuleList",query:{ActivityType:2}})
           }
        },
      
  },
  components: {
      ActivityItem
  },
};
</script>
